import { Link } from "gatsby"
import React from "react"
import Logo from "./Logo"

const Header = () => {
  return (
    <header
      className="p-4 sm:p-8 sticky top-0 left-0 opacity-90 bg-gray-900 z-50"
      style={{ backdropFilter: "blur(4px)" }}
    >
      <div className="flex justify-between items-center">
        <Link
          to="/"
          className="no-underline p-1 sm:p-2 rounded-full text-coolGray-200 hover:bg-gray-50 hover:text-gray-900 transition ease-in-out duration-500"
        >
          <Logo size={48} className="text-current" />
        </Link>
        <div className="flex items-center">
          <Link
            to="/#work"
            className="font-mono text-sm text-gray-500 hover:text-gray-50 cursor-pointer"
          >
            Work
          </Link>
          <Link
            to="/#side-projects"
            className="font-mono text-sm text-gray-500 hover:text-gray-50 cursor-pointer mx-8"
          >
            Side Projects
          </Link>
          <Link
            to="/#contact"
            className="font-mono text-sm text-gray-500 hover:text-gray-50 cursor-pointer"
          >
            Contact
          </Link>
        </div>
      </div>
    </header>
  )
}

export default Header
