import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

export default function Footer() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "headshot-square-small.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="h-72 w-full text-gray-600 px-12">
      <div className="flex justify-center">
        <div>
          <Img
            className="h-24 w-24 rounded-full"
            fluid={data.file.childImageSharp.fluid}
          />
        </div>
      </div>
    </div>
  )
}
