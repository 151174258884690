import React from "react"

interface IProps {
  className?: string
  size?: number
}

export default function Logo({ className, size = 24 }: IProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      fill="currentColor"
      className={className}
    >
      <path d="M5.02389 6.48637V11.1664H10.673V12.6729H5.02389V17.4935H11.3402V19H3V5.00001L11.3402 5V6.48637H5.02389Z" />
      <path d="M18.5758 19L14.8839 13.2755H12.4374V19H10.4135V5H15.4176C16.589 5 17.575 5.18077 18.3756 5.54232C19.1911 5.90387 19.799 6.39264 20.1993 7.00861C20.5997 7.62458 20.7998 8.3276 20.7998 9.11765C20.7998 10.0818 20.4885 10.9321 19.8657 11.6686C19.2578 12.4051 18.3386 12.8938 17.1079 13.1349L21 19H18.5758ZM12.4374 11.8092H15.4176C16.5148 11.8092 17.3377 11.5681 17.8863 11.0861C18.4349 10.5906 18.7092 9.93448 18.7092 9.11765C18.7092 8.28742 18.4349 7.64467 17.8863 7.18938C17.3526 6.7341 16.5297 6.50646 15.4176 6.50646H12.4374V11.8092Z" />
    </svg>
  )
}
